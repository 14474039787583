import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Layout } from "../components/layout";
const aboutStyle = require('../styles/about.module.scss');
export const About = ({ data }) => {
    const about = data.allMdx.nodes[0];
    return <Layout>
        <main id="main" role="main" className={aboutStyle.aboutStack}>
            <div className={aboutStyle.aboutText}>
                <MDXRenderer>{about.body}</MDXRenderer>
            </div>
        </main>
    </Layout>;
};
export const query = graphql `
    query IndexMarkdownDocument {
        allMdx(filter: {frontmatter: {type: {eq: "about"}}}, limit: 1) {
            nodes {
                body
            }
        }
    }`;
export default About;
