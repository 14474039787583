import React from 'react';
import { Link } from 'gatsby';
const bannerStyle = require('./banner.module.scss');
//        <div aria-hidden={true} className="background"/>
//<li><Link to="/#today">Veranstaltungen</Link></li>
// <li><a href="https://images.warmermai.ch/static/warmer-mai-2021_eflyer.pdf">Flyer</a></li>
// <li><a href="/register">Event anmelden</a></li>
export const Layout = ({ children }) => {
    return <React.Fragment>
        <header role="banner" className={`${bannerStyle.banner} withBackground`}>
            <div className={bannerStyle.navigation}>
                <ul role="navigation">
                    <li><Link to="/archive">Archiv</Link></li>
                    <li><Link to="/about">Über uns</Link></li>
                    <li><a href="/register">Veranstaltung anmelden</a></li>
                    <li><a href="https://www.facebook.com/warmermai">
                        <img src="https://images.warmermai.ch/static/f_logo_RGB-White_58.png" alt="Facebook" style={{ position: "relative", height: "2ex", top: "10%" }}></img>
                    </a></li>
                    <li><a href="https://www.instagram.com/warmermai/">
                        <img src="https://images.warmermai.ch/static/Instagram_Glyph_White-Small.png" alt="Instagram" style={{ position: "relative", height: "2ex", top: "10%" }}></img>
                    </a></li>
                </ul>
            </div>
            <div>
                <Link to="/">«warmer mai»</Link>
            </div>
            <script defer data-domain="warmermai.ch" src="https://plausible.io/js/script.js"></script>
        </header>
        {children}
    </React.Fragment>;
};
